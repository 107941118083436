import {
  useState,
  ReactNode,
  createContext,
  useContext,
  useCallback,
} from "react";
import { ProductResponse } from "../../../globals/interfaces/product_response";
import {
  CheckoutSummary,
  ProjectOrderProducts,
} from "../../../globals/interfaces/project_order";

interface ProjectOrderData {
  orders: ProjectOrderProducts;
  setOrders: React.Dispatch<React.SetStateAction<ProjectOrderProducts>>;
  ordersTemp: ProjectOrderProducts;
  setOrdersTemp: React.Dispatch<React.SetStateAction<ProjectOrderProducts>>;
  expectsDates: string[];
  setExpectsDates: React.Dispatch<React.SetStateAction<string[]>>;
  activeDate: string;
  setActiveDate: React.Dispatch<React.SetStateAction<string>>;
  summary: CheckoutSummary;
  setSummary: React.Dispatch<React.SetStateAction<CheckoutSummary>>;
  handleAddProductInProjection: (product: ProductResponse) => void;
}
interface ProjectOrderProviderProps {
  children: ReactNode;
}

const innitializeSummary: CheckoutSummary = {
  invoiceDiscount: 0,
  itensQuantity: 0,
  shipping: 0,
  totalProducts: 0,
  totalToPay: 0,
};

export const ProjectOrderContext = createContext({} as ProjectOrderData);

export const ProjectOrderProvider = ({
  children,
}: ProjectOrderProviderProps) => {
  const [orders, setOrders] = useState({} as ProjectOrderProducts);
  const [ordersTemp, setOrdersTemp] = useState({} as ProjectOrderProducts);
  const [expectsDates, setExpectsDates] = useState<string[]>([]);
  const [activeDate, setActiveDate] = useState<string>("");
  const [summary, setSummary] = useState<CheckoutSummary>(innitializeSummary);

  const handleAddProductInProjection = useCallback(
    (product: ProductResponse) => {
      const currentOrdersTemp = ordersTemp.dates
        ? ordersTemp.dates[expectsDates[0] || "noSelected"]
        : null;
      if (currentOrdersTemp) {
        setOrdersTemp({
          dates: {
            [expectsDates[0] || "noSelected"]: [
              ...currentOrdersTemp,
              {
                paused: false,
                quantity: product.min_quantity,
                recurrence: null as any,
                sku: product.id,
                product,
              },
            ],
          },
          valid_to_order: true,
        });
      } else {
        setOrdersTemp({
          dates: {
            [expectsDates[0] || "noSelected"]: [
              {
                paused: false,
                quantity: product.min_quantity,
                recurrence: null as any,
                sku: product.id,
                product,
              },
            ],
          },
          valid_to_order: true,
        });
      }
    },
    [ordersTemp, expectsDates]
  );

  return (
    <ProjectOrderContext.Provider
      value={{
        expectsDates,
        orders,
        setExpectsDates,
        setOrders,
        activeDate,
        setActiveDate,
        setSummary,
        summary,
        handleAddProductInProjection,
        ordersTemp,
        setOrdersTemp,
      }}
    >
      {children}
    </ProjectOrderContext.Provider>
  );
};

export const useProjectOrder = () => {
  const context = useContext(ProjectOrderContext);

  return context;
};
