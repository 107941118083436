import React from 'react';

import { IGlobalContext } from './types';
import useStates from './states';

const GlobalContext = React.createContext({} as IGlobalContext);

interface IProps {
  children?: React.ReactNode;
};

export const GlobalContextProvider: React.FC<IProps> = ({ children }) => {
  const states = useStates();

  return (
    <GlobalContext.Provider
      value={
        {
          
        }
      }
    >
      {children}
    </GlobalContext.Provider>
  );
};

export function useGlobalContext() {
  const context = React.useContext(GlobalContext);
  return context;
};
