// APIs
import { loginAPI, checkSessionAPI, logoutAPI } from "../../services/auth";

// Types and interfaces
import { IAuthStates } from "./types";
import { User } from "../../globals/interfaces/user";

// Others imports
import LocalStorage from "../storage/LocalStorage";

export interface IUseAPI {
  login: (login: string, password: string) => void;
  logout: () => void;
  checkSession: () => void;
}

// APIs =============================================================================================================================================
function useAPIs(states: IAuthStates): IUseAPI {
  const login = (login: string, password: string) => {
    states.setIsQueryingAPI(true);

    loginAPI(login, password)
      .then(async (response) => {
        console.log("response => loginAPI", response);
        // Verify if exist errors
        if (typeof response.data.error !== "undefined") {
          states.setErrorMessage(response.data.error);
          states.setLoggedUser(null);

          return;
        }

        checkSession();
      })
      .catch((error) => {
        states.setErrorMessage(error.data.message);
        states.setLoggedUser(null);
      })
      .finally(() => {
        states.setIsQueryingAPI(false);
      });
  };

  const logout = () => {
    states.setIsQueryingAPI(true);

    logoutAPI()
      .then((response) => {
        console.log("response => logoutAPI", response);

        // Verify if exist errors
        if (typeof response.data.error !== "undefined") {
          states.setErrorMessage(response.data.error);

          return;
        }

        states.setLoggedUser(null);
      })
      .catch((error) => {
        states.setErrorMessage(error.data.message);

        states.setLoggedUser(null);
      })
      .finally(() => {
        states.setIsQueryingAPI(false);
      });
  };

  const checkSession = () => {
    states.setLoggedUser(undefined);

    // RETIRAR
    LocalStorage.setHasNavbar(true);
    LocalStorage.setHasSidebar(true);

    checkSessionAPI()
      .then((response) => {
        console.log("response => checkSessionAPI", response);

        // Verify if exist errors
        if (typeof response.data.error !== "undefined") {
          throw new Error(response.data.error);
        }

        if (response.data.session) states.setLoggedUser(response.data as User);
        else states.setLoggedUser(null);
      })
      .catch((error) => {
        states.setErrorMessage(error.message);

        states.setLoggedUser(null);
      })
      .finally(() => { });
  };

  return {
    login,
    logout,
    checkSession,
  };
}

export default useAPIs;
