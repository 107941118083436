import React, { ReactNode } from "react";
import useFeedback, { IUseFeedback } from "../../../hooks/feedbacks";

import {
  FilterState,
  IsFilteringState,
  InvoicesState,
  OpenState,
} from "./types";

export interface IUseStates extends IUseFeedback {
  filter: FilterState;
  setFilter: React.Dispatch<React.SetStateAction<FilterState>>;

  isFiltering: IsFilteringState;
  setIsFiltering: React.Dispatch<React.SetStateAction<IsFilteringState>>;

  invoices: InvoicesState;
  setInvoices: React.Dispatch<React.SetStateAction<InvoicesState>>;

  open: OpenState;
  setOpen: React.Dispatch<React.SetStateAction<OpenState>>;
}

export interface InvoicesProviderProps {
  children: ReactNode;
}

export const InvoicesContext = React.createContext({} as IUseStates);

export const InvoicesProvider = ({ children }: InvoicesProviderProps) => {
  const feedbacks = useFeedback();

  const [filter, setFilter] = React.useState<FilterState>({
    filterType: "all",
    filterText: "",
    orderBy: {
      field: "",
      order: "ASC",
    },
  });
  const [isFiltering, setIsFiltering] = React.useState<IsFilteringState>(false);

  const [invoices, setInvoices] = React.useState<InvoicesState>([]);

  const [open, setOpen] = React.useState<OpenState>(false);

  return (
    <InvoicesContext.Provider
      value={{
        ...feedbacks,

        filter,
        setFilter,

        isFiltering,
        setIsFiltering,

        invoices,
        setInvoices,

        open,
        setOpen,
      }}
    >
      {children}
    </InvoicesContext.Provider>
  );
};

export default function useStates(): IUseStates {
  const context = React.useContext(InvoicesContext);

  return {
    ...context,
  };
}
