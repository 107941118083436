export const REACT_APP_PLACE =
  process.env.REACT_APP_PLACE === "web" ? "web" : "local";

export const API_BASE_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PRODUCTION_API_URL_BASE
    : REACT_APP_PLACE === "local"
    ? process.env.REACT_APP_DEVELOPMENT_LOCAL_API_URL_BASE
    : process.env.REACT_APP_DEVELOPMENT_API_URL_BASE;

export const API_BASE_URL_ECOMMERCE =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_ECOMMERCE_PRODUCTIONT_API_URL
    : REACT_APP_PLACE === "local"
    ? process.env.REACT_APP_ECOMMERCE_DEVELOPMENT_LOCAL_API_URL
    : process.env.REACT_APP_ECOMMERCE_DEVELOPMENT_API_URL;

export const APP_ROOT = "";
export const API_ROOT = "v2/api/admin";
export const IMAGE_URL_PREFIX = "";

/**
 * NOT LOGGED IN USER SCREENS ROUTES
 */
export const SCREEN_LOGIN = `${APP_ROOT}/login/`;

/**
 * LOGGED IN USER SCREENS ROUTES
 */
export const SCREEN_DASHBOARD = `${APP_ROOT}/dashboard/`;

// Registers
export const SCREEN_REGISTER_CLIENT = `${APP_ROOT}/cadastros/clientes/`;
export const SCREEN_REGISTER_CLIENT_NEW = `${APP_ROOT}/cadastros/cliente/`;
export const SCREEN_REGISTER_CLIENT_UPDATE = `${APP_ROOT}/cadastros/cliente/:id/`;

export const SCREEN_REGISTER_SKU = `${APP_ROOT}/cadastros/skus/`;
export const SCREEN_REGISTER_SKU_NEW = `${APP_ROOT}/cadastros/sku/`;
export const SCREEN_REGISTER_SKU_UPDATE = `${APP_ROOT}/cadastros/sku/:id/`;

export const SCREEN_ECOMMERCE_MODAL = `${APP_ROOT}/ecommerce/modal`;
export const SCREEN_ECOMMERCE_MODAL_CREATE = `${APP_ROOT}/ecommerce/modal/novo`;
export const SCREEN_ECOMMERCE_MODAL_UPDATE = `${APP_ROOT}/ecommerce/modal/editar/:id`;

export const SCREEN_REGISTER_SELLER = `${APP_ROOT}/cadastros/lojas/`;
export const SCREEN_REGISTER_SELLER_NEW = `${APP_ROOT}/cadastros/loja/`;
export const SCREEN_REGISTER_SELLER_UPDATE = `${APP_ROOT}/cadastros/loja/:id/`;

export const SCREEN_REGISTER_CATEGORY = `${APP_ROOT}/cadastros/categorias/`;
export const SCREEN_REGISTER_CATEGORY_NEW = `${APP_ROOT}/cadastros/categoria/`;
export const SCREEN_REGISTER_CATEGORY_UPDATE = `${APP_ROOT}/cadastros/categoria/:id/`;

// Stock
export const SCREEN_STOCK_GENERAL = `${APP_ROOT}/estoque/geral/`;
export const SCREEN_STOCK_INVOICES = `${APP_ROOT}/estoque/notas/`;
export const SCREEN_STOCK_INVOICE_DETAILS = `${APP_ROOT}/estoque/nota/:id/`;
export const SCREEN_STOCK_DETAILS = `${APP_ROOT}/estoque/detalhes/:id/`;

// Report
export const SCREEN_REPORT = `${APP_ROOT}/relatorios/`;

// Order
export const SCREEN_ORDER_INVOICE_EMIT = `${APP_ROOT}/pedidos/emitir-nota/`;
export const SCREEN_ORDER_LIST = `${APP_ROOT}/pedidos/`;
export const SCREEN_ORDER_DETAILS = `${APP_ROOT}/pedido/:id/`;
export const SCREEN_ORDER_RECURRENCE_LIST = `${APP_ROOT}/pedidos/recorrencias/`;
export const SCREEN_ORDER_RECURRENCE_DETAILS = `${APP_ROOT}/pedidos/recorrencia/:id/`;

// Promotion
export const SCREEN_PROMOTION_COUPON_LIST = `${APP_ROOT}/promocoes/cupons/`;
export const SCREEN_PROMOTION_COUPON_NEW = `${APP_ROOT}/promocoes/cupom/`;
export const SCREEN_PROMOTION_COUPON_VIEW = `${APP_ROOT}/promocoes/cupom/:id/`;

// PROJECT ORDER
export const SCREEN_PROJECT_ORDER = `${APP_ROOT}/projecao-de-pedidos`;
export const SCREEN_PROJECT_ORDER_DETAILS = `${APP_ROOT}/projecao-de-pedidos/:id/`;

// Seller Order
export const SCREEN_SELLER_ORDER_NEW = `${APP_ROOT}/pedido-para-seller/pedido/`; // New Seller Order
export const SCREEN_SELLER_ORDER_GET_ALL = `${APP_ROOT}/pedido-para-seller/pedidos/`;
export const SCREEN_SELLER_ORDER_DETAILS = `${APP_ROOT}/pedido-para-seller/pedido/:id/`; // Edit Seller Order

/**
 * APIs
 */

// Auth routes
export const API_AUTH_LOGIN = `${API_ROOT}/auth/Login`; // POST
export const API_AUTH_CHECK_SESSION = `${API_ROOT}/auth/CheckSession`; // POST
export const API_AUTH_LOGOUT = `${API_ROOT}/auth/Logout`; // GET

// User
export const API_USER_ALTER_PASSWORD = `${API_ROOT}/User/ChangePassword`; // POST

// Dashboard routes
export const API_DASHBOARD_ORDER_PROCESSING_PROBLEM = `${API_ROOT}/dashboard/order/ProcessingProblem`;
export const API_DASHBOARD_STOCK_MISSING = `${API_ROOT}/dashboard/stock/Missing`;
export const API_DASHBOARD_LOW_SHELF_LIFE = `${API_ROOT}/dashboard/stock/LowShelfLife`;

/**
 * Registers API
 */

// Client
export const API_REGISTER_CLIENT_GET_ALL = `${API_ROOT}/ecommerce_user/Table`; // GET
export const API_REGISTER_CLIENT_GET = `${API_ROOT}/ecommerce_user/Details/:id`; // GET
export const API_REGISTER_CLIENT_NEW = `${API_ROOT}/ecommerce_user/Create`; // POST
export const API_REGISTER_CLIENT_UPDATE = `${API_ROOT}/ecommerce_user/Update`; // POST

// SKU
export const API_REGISTER_SKU_GET_ALL = `${API_ROOT}/sku/Table`;
export const API_REGISTER_SKU_GET = `${API_ROOT}/sku/Details/:id`;
export const API_REGISTER_SKU_NEW = `${API_ROOT}/sku/Create`;
export const API_REGISTER_SKU_UPDATE = `${API_ROOT}/sku/Update`;

// Seller
export const API_REGISTER_SELLER_GET_ALL = `${API_ROOT}/seller/Table`; // GET
export const API_REGISTER_SELLER_GET = `${API_ROOT}/seller/Details/:id`; // GET
export const API_REGISTER_SELLER_NEW = `${API_ROOT}/seller/Create`; // POST
export const API_REGISTER_SELLER_UPDATE = `${API_ROOT}/seller/Update`; // POST

// Category
export const API_REGISTER_CATEGORY_GET_ALL = `${API_ROOT}/category/Table`; // GET
export const API_REGISTER_CATEGORY_GET = `${API_ROOT}/category/Details/:id`; // GET
export const API_REGISTER_CATEGORY_NEW = `${API_ROOT}/category/Create`; // POST
export const API_REGISTER_CATEGORY_UPDATE = `${API_ROOT}/category/Update`; // POST

/**
 * Stock API
 */
export const API_STOCK_GENERAL_TABLE = `${API_ROOT}/StockTable`; // GET
export const API_STOCK_INVOICE_GET_ALL = `${API_ROOT}/BuyInvoice/Table`; // GET
export const API_STOCK_UPLOAD_XML = `${API_ROOT}/BuyInvoice/Upload`; // POST
export const API_STOCK_INVOICE_GET = `${API_ROOT}/BuyInvoice/Details/:id`; // GET
export const API_STOCK_INVOICE_INPUT = `${API_ROOT}/BuyInvoice/InputStock`; // POST
export const API_STOCK_DETAILS = `${API_ROOT}/StockDetails/:id`; // GET
export const API_STOCK_ITEM_NEW = `${API_ROOT}/Stock/Operations/Add`; // POST
export const API_STOCK_ITEM_SUB = `${API_ROOT}/Stock/Operations/Subtract`; // POST
export const API_STOCK_ITEM_ADD = `${API_ROOT}/Stock/Operations/Add`; // POST
export const API_STOCK_ITEM_CHANGE_EXPIRY_DATE = `${API_ROOT}/Stock/Operations/TradeExpiry`; // POST

/**
 * Report API
 */
export const API_REPORT_DANFE = `${API_ROOT}/Report/Danfe`; // GET
export const API_REPORT_ORDERS_TO_SELLERS = `${API_ROOT}/Report/OrderToSeller`; // GET
export const API_REPORT_USER = `${API_ROOT}/Report/EcommerceUser`; // GET
export const API_REPORT_ORDER = `${API_ROOT}/Report/Order`; // GET
export const API_REPORT_PROJECT_ORDER = `${API_ROOT}/Report/ProjectOrder`; // GET
export const API_REPORT_ORDER_SIMPLIFIED = `${API_ROOT}/Report/OrderSimplified`; // GET
export const API_REPORT_PROJECTION_ORDER_RECURRENCE = `${API_ROOT}/Report/StockProjection`; // GET
export const API_REPORT_RECURRENCE = `${API_ROOT}/Report/NextRecurrence`; // GET
export const API_REPORT_SELL_XML = `${API_ROOT}/Report/SellXml`; // GET
export const API_REPORT_SELLER_CODE_MAP = `${API_ROOT}/Report/SellerCodeMap`; // GET
export const API_REPORT_STOCK_VALUE = `${API_ROOT}/Report/StockValueReport`; // GET
export const API_REPORT_STOCK = `${API_ROOT}/Report/Stock`; // GET
export const API_REPORT_SALE_PROMOTION_DISCOUNT = `${API_ROOT}/Report/SalePromotionDiscount`; // GET

/**
 * Order API
 */
export const API_ORDER_VIEW_ORDERS = `${API_ROOT}/InvoiceEmit/ViewOrders`; // GET
export const API_ORDER_INVOICE_EMIT = `${API_ROOT}/InvoiceEmit/RunEmit`; // POST
export const API_ORDER_INVOICE_EMIT_STATUS = `${API_ROOT}/InvoiceEmit/Status`; // GET
export const API_ORDER_INVOICE_EMIT_CANCEL = `${API_ROOT}/InvoiceEmit/Cancel`; // POST
export const API_ORDER_GET_ALL = `${API_ROOT}/Order/Table`; // GET
export const API_ORDER_GET = `${API_ROOT}/Order/Details/:id`; // GET
export const API_ORDER_RECURRENCE_GET_ALL = `${API_ROOT}/recurrence/Table`; // GET
export const API_ORDER_RECURRENCE_GET = `${API_ROOT}/recurrence/Details/:id`; // GET
export const API_ORDER_UPDATE = `${API_ROOT}/Order/EditOrder`; // POST
export const API_ORDER_NEW = `${API_ROOT}/Order/CreateSingle`; // POST
export const API_ORDER_PAID_WITH_PIX = `${API_ROOT}/Order/PaidWithPix`; // POST
export const API_ORDER_REPROCESS_PAYMENT = `${API_ROOT}/Order/ReprocessPayment`; // POST
export const API_ORDER_CANCEL = `${API_ROOT}/Order/Cancel`; // POST
export const API_ORDER_CHANGE_SHIPPING_DATE = `${API_ROOT}/Order/ChangeShippingDate`; // POST
export const API_ORDER_BULK_PRODUCT_BONUS = `${API_ROOT}/Order/BulkProductBonus`; // POST
export const API_ORDER_CHANGE_ADDRESS = `${API_ROOT}/Order/EditEcommerceUserInfo`; // POST
export const API_ORDER_CANCEL_INVOICE = `${API_ROOT}/Order/CancelInvoice`; // POST
export const API_ORDER_SEND_MAIL_REPROCESSED_UNSUCCESSFULLY = `${API_ROOT}/Order/SendMailReprocessedUnseccessfully`; // POST

/**
 * Promotion API
 */
export const API_PROMOTION_COUPON_GET_ALL = `${API_ROOT}/sale_promotion/Table`; // GET
export const API_PROMOTION_COUPON_GET = `${API_ROOT}/sale_promotion/Details/:id`; // GET
export const API_PROMOTION_COUPON_NEW = `${API_ROOT}/sale_promotion/Create`; // POST
export const API_PROMOTION_COUPON_UPDATE = `${API_ROOT}/sale_promotion/Update`; // POST

/**
 * Project order
 */
export const API_PROJECT_ORDER = `${API_ROOT}/project_order/Table`; // GET
export const API_PROJECT_ORDER_DETAILS = `${API_ROOT}/project_order/Details/:id`; // GET
export const API_UPDATE_PRODUCT = `${API_ROOT}/project_order/UpdateProduct`; // POST
export const API_PROJECT_ORDER_UPDATE_DT = `${API_ROOT}/project_order/UpdateDt`; // POST
export const API_PROJECT_ORDER_UPDATE_VALID_TO_ORDER = `${API_ROOT}/project_order/update_valid_to_order`; // POST

/**
 * Products ecommerce
 */
export const API_PRODUCTS_ECOMMERCE_DETAILS = `/ecommerce/products:id`; // GET
export const API_CHECKOT_SUMMARY_DETAILS = `/ecommerce/CheckoutSummary`; // POST
export const API_ORDER_OPTIONS_DT = `/ecommerce/order_options_dt?cep=swapcep`; // GET

/**
 * Others
 */

export const API_OTHERS_ADDRESS_FROM_CEP = `v2/api/cep/:cep`;
export const API_OTHERS_UPLOAD_IMAGE = `${API_ROOT}/ImageRepository/Upload`;

/**
 * Sellers Order
 */
export const API_SELLER_ORDER_GET_ALL = `${API_ROOT}/OrderToSeller/Table`; // GET
export const API_SELLER_ORDER_NEW = `${API_ROOT}/OrderToSeller/Create`; // POST
export const API_SELLER_ORDER_GET = `${API_ROOT}/OrderToSeller/Details/:id`; // GET
export const API_SELLER_ORDER_UPDATE = `${API_ROOT}/OrderToSeller/Update`; // POST
export const API_SELLER_ORDER_PREDICTIONS = `${API_ROOT}/OrderToSeller/SkuSold`; // POST
