import axios from "../globals/axios";
import * as Routes from "../globals/routes";

export function loginAPI(login: string, password: string) {
  return axios.post(Routes.API_AUTH_LOGIN, {
    login: login,
    password: password,
  });
}

export function checkSessionAPI() {
  return axios.get(Routes.API_AUTH_CHECK_SESSION);
}

export function logoutAPI() {
  return axios.get(Routes.API_AUTH_LOGOUT);
}
