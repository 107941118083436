import React from "react";
import { ThemeProvider } from "@mui/material";

import defaultThemme from "./ui/theme/defaultTheme";

import { GlobalContextProvider } from "./features/globalContext/context";
import { AuthContextProvider } from "./features/auth/context";
import { InvoicesProvider } from "./screens/stock/BuyInvoicesList/states";
import Router from "./features/router/Router";
import { GeneralStockProvider } from "./screens/stock/GeneralStock/states";
import { StockDetailsProvider } from "./screens/stock/StockDetails/states";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
      <ThemeProvider theme={defaultThemme}>
        <GlobalContextProvider>
          <AuthContextProvider>
            <GeneralStockProvider>
              <StockDetailsProvider>
                <InvoicesProvider>
                  <Router />
                </InvoicesProvider>
              </StockDetailsProvider>
            </GeneralStockProvider>
          </AuthContextProvider>
        </GlobalContextProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
