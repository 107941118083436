import React from "react";
import useFeedback, { IUseFeedback } from "../../../hooks/feedbacks";

import {
  FilterState,
  IsFilteringState,
  StockState,
  KeyRowState,
  OperationState,
} from "./types";

export interface IUseStates extends IUseFeedback {
  filter: FilterState;
  setFilter: React.Dispatch<React.SetStateAction<FilterState>>;

  isFiltering: IsFilteringState;
  setIsFiltering: React.Dispatch<React.SetStateAction<IsFilteringState>>;

  stock: StockState;
  setStock: React.Dispatch<React.SetStateAction<StockState>>;

  keyRow: KeyRowState;
  setKeyRow: React.Dispatch<React.SetStateAction<KeyRowState>>;

  operation: OperationState;
  setOperation: React.Dispatch<React.SetStateAction<OperationState>>;
}

interface StockDetailsProviderProps {
  children: React.ReactNode;
}

export const StockDetailsContext = React.createContext(
  {} as Pick<IUseStates, "stock" | "setStock">
);
export const StockDetailsProvider = ({
  children,
}: StockDetailsProviderProps) => {
  const [stock, setStock] = React.useState<StockState>(null);

  return (
    <StockDetailsContext.Provider value={{ setStock, stock }}>
      {children}
    </StockDetailsContext.Provider>
  );
};

export default function useStates(): IUseStates {
  const feedbacks = useFeedback();
  const context = React.useContext(StockDetailsContext);
  const [filter, setFilter] = React.useState<FilterState>({
    filterType: "all",
    filterText: "",
    orderBy: {
      field: "",
      order: "ASC",
    },
  });
  const [isFiltering, setIsFiltering] = React.useState<IsFilteringState>(false);

  const [keyRow, setKeyRow] = React.useState<KeyRowState>(null);
  const [operation, setOperation] = React.useState<OperationState>(undefined);

  return {
    ...feedbacks,

    filter,
    setFilter,

    isFiltering,
    setIsFiltering,

    ...context,

    keyRow,
    setKeyRow,

    operation,
    setOperation,
  };
}
