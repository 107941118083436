import axios from "axios";
import { globalAuth } from "../features/auth/context";
import { API_BASE_URL } from "./routes";

const baseURL = API_BASE_URL;
const apiAxios = axios.create({ baseURL: baseURL, withCredentials: true });

apiAxios.interceptors.response.use(
  (response) => {
    if (typeof response.data === "undefined")
      response.data = { error: "Erro em chamada da aPI" };

    return response;
  },
  (error) => {
    if (typeof error.data === "undefined")
      error.data = { error: "Erro em chamada de API" };

    if (typeof error.response === "undefined")
      error.response = { error: "Erro em chamada de API" };

    if (error.response.status === 401 || error.response.status === 403) {
      console.log("Cliente foi deslogado");
      globalAuth.logout();
    }

    return error;
  }
);

export default apiAxios;
