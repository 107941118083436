import React from 'react';

import {
  IsQueryingAPIState,
  DialogMessageState,

  BlockPromptMessageState,
} from './types';

export interface IUseFeedback {
  isQueryingAPI: IsQueryingAPIState;
  setIsQueryingAPI: React.Dispatch<React.SetStateAction<IsQueryingAPIState>>;

  dialogMessage: DialogMessageState;
  setDialogMessage: React.Dispatch<React.SetStateAction<DialogMessageState>>;

  blockPromptMessage: BlockPromptMessageState;
  setBlockPromptMessage: React.Dispatch<React.SetStateAction<BlockPromptMessageState>>;
};

export default function useFeedback(): IUseFeedback {
  const [isQueryingAPI, setIsQueryingAPI] = React.useState<IsQueryingAPIState>(false);
  const [dialogMessage, setDialogMessage] = React.useState<DialogMessageState>(undefined);

  const [blockPromptMessage, setBlockPromptMessage] = React.useState<BlockPromptMessageState>(undefined);

  return {
    isQueryingAPI,
    setIsQueryingAPI,

    dialogMessage,
    setDialogMessage,

    blockPromptMessage,
    setBlockPromptMessage,
  };
};