import { Suspense, lazy, ElementType } from "react";
// components
import LoadingScreen from "../../ui/components/LoadingScreen";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

/**
 * Not Logged
 */
export const Login = Loadable(lazy(() => import("../../screens/Login")));

/**
 * Logged
 */
export const Dashboard = Loadable(
  lazy(() => import("../../screens/Dashboard"))
);

export const EcommerceModalList = Loadable(
  lazy(() => import("../../screens/ecommerceModal/EcommerceModalList"))
);
export const ModalSet = Loadable(
  lazy(() => import("../../screens/ecommerceModal/ModalSet"))
);

// Registers
export const ClientList = Loadable(
  lazy(() => import("../../screens/registers/client/ClientList"))
);
export const ClientSet = Loadable(
  lazy(() => import("../../screens/registers/client/ClientSet"))
);

export const SkuList = Loadable(
  lazy(() => import("../../screens/registers/sku/SkuList"))
);
export const SkuSet = Loadable(
  lazy(() => import("../../screens/registers/sku/SkuSet"))
);

export const SellerList = Loadable(
  lazy(() => import("../../screens/registers/seller/SellerList"))
);
export const SellerSet = Loadable(
  lazy(() => import("../../screens/registers/seller/SellerSet"))
);

export const CategoryList = Loadable(
  lazy(() => import("../../screens/registers/category/CategoryList"))
);
export const CategorySet = Loadable(
  lazy(() => import("../../screens/registers/category/CategorySet"))
);

// Stock
export const GeneralStock = Loadable(
  lazy(() => import("../../screens/stock/GeneralStock"))
);
export const BuyInvoicesList = Loadable(
  lazy(() => import("../../screens/stock/BuyInvoicesList"))
);
export const BuyInvoiceDetails = Loadable(
  lazy(() => import("../../screens/stock/BuyInvoiceDetails"))
);
export const StockDetails = Loadable(
  lazy(() => import("../../screens/stock/StockDetails"))
);

// Report
export const Report = Loadable(
  lazy(() => import("../../screens/reports/Report"))
);

// Promotion
export const CouponList = Loadable(
  lazy(() => import("../../screens/promotions/CouponList"))
);
export const CouponSet = Loadable(
  lazy(() => import("../../screens/promotions/CouponSet"))
);
export const CouponView = Loadable(
  lazy(() => import("../../screens/promotions/CouponView"))
);

// Order
export const OrderList = Loadable(
  lazy(() => import("../../screens/orders/OrderList"))
);
export const InvoiceEmit = Loadable(
  lazy(() => import("../../screens/orders/InvoiceEmit"))
);
export const OrderDetails = Loadable(
  lazy(() => import("../../screens/orders/OrderDetails"))
);
export const RecurrenceList = Loadable(
  lazy(() => import("../../screens/orders/RecurrenceList"))
);
export const RecurrenceDetails = Loadable(
  lazy(() => import("../../screens/orders/RecurrenceDetails"))
);

// ProjectOrders
export const ProjectOrder = Loadable(
  lazy(() => import("../../screens/ProjectOrder"))
);
export const ProjectOrderDetails = Loadable(
  lazy(() => import("../../screens/ProjectOrder/ProjectOrderDetails"))
);

// Seller Order
export const SelectSeller = Loadable(
  lazy(() => import("../../screens/sellerOrder/SelectSeller"))
);
export const SetSellerOrder = Loadable(
  lazy(() => import("../../screens/sellerOrder/SetSellerOrder"))
);
export const SellerOrderList = Loadable(
  lazy(() => import("../../screens/sellerOrder/SellerOrderList"))
);
