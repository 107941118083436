class StoragedValues {
  private static instance: StoragedValues;

  private constructor() { };

  public static getInstance(): StoragedValues {
    if (!StoragedValues.instance) {
      StoragedValues.instance = new StoragedValues();
    };

    return StoragedValues.instance;
  };

  /**
   * Set values
   */

  public setHasNavbar(b: boolean): void {
    localStorage.setItem('hasNavbar', b ? 'true' : 'false');
  };

  public setHasSidebar(b: boolean): void {
    localStorage.setItem('hasSidebar', b ? 'true' : 'false');
  };


  /**
   * Get values
   */

  public getHasNavbar(): boolean {
    if (!localStorage.getItem('hasNavbar'))
      this.setHasNavbar(this.getDefaultHasNavbar());

    return localStorage.getItem('hasNavbar') === 'true';
  };

  public getHasSidebar(): boolean {
    if (!localStorage.getItem('hasSidebar'))
      this.setHasSidebar(this.getDefaultHasSidebar());

    return localStorage.getItem('hasSidebar') === 'true';
  };


  /**
   * Default values
   */

  public getDefaultHasNavbar(): boolean {
    return true;
  };

  public getDefaultHasSidebar(): boolean {
    return true;
  };
};

export default StoragedValues.getInstance();
