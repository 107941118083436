import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import * as GlobalRoutes from "../../globals/routes";

import CheckSession from "../auth/CheckSession";

import {
  /**
   * Not Logged
   */
  Login,

  /**
   * Logged
   */
  Dashboard,

  // Registers
  ClientList,
  ClientSet,
  SkuList,
  SkuSet,
  SellerList,
  SellerSet,
  CategoryList,
  CategorySet,

  // Stock
  GeneralStock,
  BuyInvoicesList,
  BuyInvoiceDetails,
  StockDetails,

  // Report
  Report,

  // Promotion
  CouponList,
  CouponSet,
  CouponView,

  // Order
  OrderList,
  InvoiceEmit,
  OrderDetails,
  RecurrenceList,
  RecurrenceDetails,

  // ProjectOrders
  ProjectOrder,
  ProjectOrderDetails,

  // Seller Order
  SelectSeller,
  SetSellerOrder,
  SellerOrderList,
  EcommerceModalList,
  ModalSet,
} from "./elements";

// ProjectOrders
import { ProjectOrderProvider } from "../../screens/ProjectOrder/ProjectOrderDetails/context";

const AllRoutes = React.memo((props) => {
  return (
    <Routes>
      {/**
       * Not Logged routes
       */}
      <Route path={GlobalRoutes.SCREEN_LOGIN} element={<Login />} />

      {/**
       * Logged routes
       */}
      <Route path={GlobalRoutes.SCREEN_DASHBOARD} element={<Dashboard />} />

      {/* Registers */}
      <Route
        path={GlobalRoutes.SCREEN_REGISTER_CLIENT}
        element={<ClientList />}
      />
      <Route
        path={GlobalRoutes.SCREEN_REGISTER_CLIENT_NEW}
        element={<ClientSet />}
      />
      <Route
        path={GlobalRoutes.SCREEN_REGISTER_CLIENT_UPDATE}
        element={<ClientSet />}
      />

      <Route path={GlobalRoutes.SCREEN_REGISTER_SKU} element={<SkuList />} />
      <Route path={GlobalRoutes.SCREEN_REGISTER_SKU_NEW} element={<SkuSet />} />
      <Route
        path={GlobalRoutes.SCREEN_REGISTER_SKU_UPDATE}
        element={<SkuSet />}
      />

      <Route
        path={GlobalRoutes.SCREEN_REGISTER_SELLER}
        element={<SellerList />}
      />
      <Route
        path={GlobalRoutes.SCREEN_REGISTER_SELLER_NEW}
        element={<SellerSet />}
      />
      <Route
        path={GlobalRoutes.SCREEN_REGISTER_SELLER_UPDATE}
        element={<SellerSet />}
      />

      <Route
        path={GlobalRoutes.SCREEN_REGISTER_CATEGORY}
        element={<CategoryList />}
      />
      <Route
        path={GlobalRoutes.SCREEN_REGISTER_CATEGORY_NEW}
        element={<CategorySet />}
      />
      <Route
        path={GlobalRoutes.SCREEN_REGISTER_CATEGORY_UPDATE}
        element={<CategorySet />}
      />
      <Route
        path={GlobalRoutes.SCREEN_ECOMMERCE_MODAL}
        element={<EcommerceModalList />}
      />
      <Route
        path={GlobalRoutes.SCREEN_ECOMMERCE_MODAL_CREATE}
        element={<ModalSet />}
      />
      <Route
        path={GlobalRoutes.SCREEN_ECOMMERCE_MODAL_UPDATE}
        element={<ModalSet />}
      />
      <Route
        path={GlobalRoutes.SCREEN_ORDER_RECURRENCE_LIST}
        element={<RecurrenceList />}
      />
      <Route
        path={GlobalRoutes.SCREEN_ORDER_RECURRENCE_DETAILS}
        element={<RecurrenceDetails />}
      />

      {/* Stock */}
      <Route
        path={GlobalRoutes.SCREEN_STOCK_GENERAL}
        element={<GeneralStock />}
      />
      <Route
        path={GlobalRoutes.SCREEN_STOCK_INVOICES}
        element={<BuyInvoicesList />}
      />
      <Route
        path={GlobalRoutes.SCREEN_STOCK_INVOICE_DETAILS}
        element={<BuyInvoiceDetails />}
      />
      <Route
        path={GlobalRoutes.SCREEN_STOCK_DETAILS}
        element={<StockDetails />}
      />

      {/* Report */}
      <Route path={GlobalRoutes.SCREEN_REPORT} element={<Report />} />

      {/* Order */}
      <Route path={GlobalRoutes.SCREEN_ORDER_LIST} element={<OrderList />} />
      <Route
        path={GlobalRoutes.SCREEN_ORDER_INVOICE_EMIT}
        element={<InvoiceEmit />}
      />
      <Route
        path={GlobalRoutes.SCREEN_ORDER_DETAILS}
        element={<OrderDetails />}
      />
      {/* <Route
        path={GlobalRoutes.SCREEN_ORDER_RECURRENCE}
        element={<Recurrence />}
      /> */}

      {/* PROJECT ORDERS */}
      <Route
        path={GlobalRoutes.SCREEN_PROJECT_ORDER}
        element={<ProjectOrder />}
      />
      <Route
        path={GlobalRoutes.SCREEN_PROJECT_ORDER_DETAILS}
        element={
          <ProjectOrderProvider>
            <ProjectOrderDetails />
          </ProjectOrderProvider>
        }
      />

      {/* PROMOTIONS */}
      <Route
        path={GlobalRoutes.SCREEN_PROMOTION_COUPON_LIST}
        element={<CouponList />}
      />
      <Route
        path={GlobalRoutes.SCREEN_PROMOTION_COUPON_NEW}
        element={<CouponSet />}
      />
      <Route
        path={GlobalRoutes.SCREEN_PROMOTION_COUPON_VIEW}
        element={<CouponView />}
      />

      {/* Seller Order */}
      <Route
        path={GlobalRoutes.SCREEN_SELLER_ORDER_NEW}
        element={<SelectSeller />}
      />
      <Route
        path={GlobalRoutes.SCREEN_SELLER_ORDER_DETAILS}
        element={<SetSellerOrder />}
      />
      <Route
        path={GlobalRoutes.SCREEN_SELLER_ORDER_GET_ALL}
        element={<SellerOrderList />}
      />

      <Route
        path="*"
        element={<Navigate to={GlobalRoutes.SCREEN_LOGIN} replace />}
      />
    </Routes>
  );
});

const Router = () => {
  return (
    <BrowserRouter>
      <CheckSession>
        <AllRoutes />
      </CheckSession>
    </BrowserRouter>
  );
};

export default Router;
