import { createTheme, PaletteColor } from '@mui/material/styles';

declare module "@mui/material/styles" {
  interface Palette {
    graffiti: PaletteColor;
  }
  interface PaletteOptions {
    graffiti: PaletteColor;
  }

  interface Palette {
    basicGray: PaletteColor;
  }
  interface PaletteOptions {
    basicGray: PaletteColor;
  }

  interface Palette {
    mint: PaletteColor;
  }
  interface PaletteOptions {
    mint: PaletteColor;
  }

  interface Palette {
    epackingGreen: PaletteColor;
  }
  interface PaletteOptions {
    epackingGreen: PaletteColor;
  }
};

const defaultTheme = createTheme();

let theme = createTheme({
  palette: {
    primary: {
      main: '#00625B',
    },
    secondary: {
      main: '#B07457',
    },
    graffiti: defaultTheme.palette.augmentColor({
      color: { main: '#272727' },
      name: 'graffiti',
    }),
    basicGray: defaultTheme.palette.augmentColor({
      color: { main: '#D9D9D9' },
      name: 'basicGray',
    }),
    mint: defaultTheme.palette.augmentColor({
      color: { main: '#B8D8C0', contrastText: '#004747' },
      name: 'mint',
    }),
    epackingGreen: defaultTheme.palette.augmentColor({
      color: { main: '#62F456', contrastText: '#004747' },
      name: 'epackingGreen',
    }),
    grey: {
      ...defaultTheme.palette.grey,
      "50": '#FEFEFE'
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
    button: {
      fontWeight: 600,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

export default theme;