import React from "react";
import useFeedback, { IUseFeedback } from "../../../hooks/feedbacks";

import { FilterState, StockStates, ColumnsState } from "./types";

export interface IUseStates extends IUseFeedback {
  filter: FilterState;
  setFilter: React.Dispatch<React.SetStateAction<FilterState>>;

  stock: StockStates;
  setStock: React.Dispatch<React.SetStateAction<StockStates>>;

  columns: ColumnsState;
  setColumns: React.Dispatch<React.SetStateAction<ColumnsState>>;
}

interface GeneralStockContextData {
  stock: StockStates;
  setStock: React.Dispatch<React.SetStateAction<StockStates>>;
}
interface GeneralStockProviderProps {
  children: React.ReactNode;
}

export const GeneralStockContext = React.createContext(
  {} as GeneralStockContextData
);
export const GeneralStockProvider = ({
  children,
}: GeneralStockProviderProps) => {
  const [stock, setStock] = React.useState<StockStates>([]);

  return (
    <GeneralStockContext.Provider value={{ stock, setStock }}>
      {children}
    </GeneralStockContext.Provider>
  );
};

export default function useStates(): IUseStates {
  const feedbacks = useFeedback();
  const { setStock, stock } = React.useContext(GeneralStockContext);
  const [filter, setFilter] = React.useState<FilterState>({
    filterType: "all",
    filterText: "",
    orderBy: {
      field: "",
      order: "ASC",
    },
  });

  const [columns, setColumns] = React.useState<ColumnsState>([]);

  return {
    ...feedbacks,

    filter,
    setFilter,

    stock,
    setStock,

    columns,
    setColumns,
  };
}
